import BottomButton from "@/components/BottomButton";
import customerInformed from "@/components/documents/customerInformed";
import { registerComponent } from "@/utils";

export const components = registerComponent({
  BottomButton,
  customerInformed,
});

export default { components };

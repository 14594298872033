<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      北京大树保险经纪有限责任公司《客户告知书》
    </p>
    <p>尊敬的客户：</p>
    <p>
      &#12288;&#12288;感谢您委托我司为您代为办理保险业务。北京大树保险经纪有限责任公司（简称“大树”）是基于投保人的保险利益、为投保人购买保险提供全流程专业服务、并向保险公司依法收取佣金的保险中介机构。大树隶属于拉卡拉控股集团（www.lakala.com），是联想控股集团（www.legendholdings.com.cn）的成员企业。
    </p>
    <p>
      &#12288;&#12288;为维护您的保险利益及相关合法权益，根据《中华人民共和国保险法》、《保险经纪人监管规定（保监会令〔2018〕3号）》的要求，我司现将有关基本情况告知您：
    </p>
    <p>&#12288;&#12288;一、公司基本情况</p>
    <p>
      &#12288;&#12288;&#12288;&#12288;（一）公司名称：北京大树保险经纪有限责任公司
    </p>
    <p>&#12288;&#12288;&#12288;&#12288;（二）公司网址：www.bigtreebroker.com</p>
    <p>
      &#12288;&#12288;&#12288;&#12288;（三）中国银保监会授予的机构编码：269681000000800
    </p>
    <p>
      &#12288;&#12288;&#12288;&#12288;（四）住所/经营场所：北京市海淀区北清路中关村壹号D1座7层718
    </p>
    <p>
      &#12288;&#12288;&#12288;&#12288;（五）经营保险经纪业务许可证有效期：2023年8月21日
    </p>
    <p>&#12288;&#12288;&#12288;&#12288;（六）全国统一客服热线：021-60870045</p>
    <p style="display: flex">
      &#12288;&#12288;&#12288;&#12288;<span
        style="display: flex; font-weight: normal"
        >（七）</span
      ><span style="display: flex; font-weight: normal"
        >业务范围：在全国区域内（港、澳、台除外）为投保人拟定投保方案、选择保险人、办理投保
        手续；协助被保险人或保单受益人进行理赔，为个人客户准备理赔资料；为企业客户提供防灾、
        防损或风险评估、风险管理咨询服务；再保险经纪业务；中国银保监会批准的其他业务。</span
      >
    </p>
    <p>
      &#12288;&#12288;二、如果您（法人、团体或自然人）委托我司作为您的保险经纪人，请一定在购买保险前认真阅读全部《保险条款》，并可要求我司执业人员对保险责任、责任免除或除外责任、投保人与被保人的权利义务、免赔额或免赔率的计算、犹豫期、等待期、理赔流程、理赔材料准备等内容进行详细的专业讲解，以确保您正确投保。
    </p>
    <p>
      &#12288;&#12288;三、根据《中华人民共和国保险法》、《保险经纪人监管规定（保监会令〔2018〕3号）》，保险经纪人因过错给投保人、被保人造成经济损失的，依法承担赔偿责任；我司已按照中国银保监会的有关监管规定投保了足额的保险经纪人职业责任险。
    </p>
    <p>
      &#12288;&#12288;四、请向我司执业人员了解《中华人民共和国保险法》等法律法规对于索赔时效、保险公司理赔时限、合同中止与失效、未成年人投保险额等相关规定，以及不履行如实告知义务、故意制造保险事故、申报情况不符等情形导致的法律后果。
    </p>
    <p>
      &#12288;&#12288;如果您发现我司经纪人存在任何误导及其他损害您合法权益的行为，可向我司投诉，消费者投诉热线：010-56710999-1148，或发送举报邮件至：service@bigtreebroker.com。
    </p>
    <p>
      &#12288;&#12288;邮寄地址（公司地址如有调整将需随之更改）：北京市海淀区北清路中关村壹号D1座7层718，邮编100094。
    </p>
    <p>&#12288;&#12288;以上告知事项敬请随时查询、核实。</p>
    <p style="text-align: right">北京大树保险经纪有限责任公司</p>
    <p style="text-align: right">2021年02月</p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class CustomerInformed extends Vue {
  static name = "customer-informed";
}
export default CustomerInformed;
</script>
<style lang="scss" src="./index.scss" scoped></style>
